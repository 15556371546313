<template>
<!--    <div class="icon_wrap" @click="likeItem()">-->
<!--        <img  class="icon heart" src="@/assets/image/main/heart_no_color.png" alt="" v-show="!likeData.LikeStatus">-->
<!--        <img  class="icon heart" src="@/assets/image/main/heart_color.png" alt="" v-show="likeData.LikeStatus">-->
<!--        <div class="icon_count">{{ returnCommas(likeData.likeCnt) }}</div>-->
<!--    </div>-->
  <div class="icon_wrap" style="cursor:pointer" @click="likeItem">
    <div class="like_img" :class="{'active': likeData.LikeStatus}" ></div>
    <div class="icon_count">{{ returnCommas(likeData.likeCnt) }}</div>
  </div>
</template>

<script>
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";
import {mapState} from "vuex";

export default {
    name: "LikeItemDetailLayout",
    mixins: [],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        likeData: {}
    },
    data() {
        return {
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        likeItem() {
            if(!util.isEmpty(this.UserInfo)){
                EventBus.$emit('like');
            }
        },
        returnCommas(x=0) {
            //return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return util.Number.numFormat(x);
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
